<template>
    <div class="add"></div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.add {
    width: 44px;
    height: 44px;
    background-color: #eff0f1;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    &::after,
    &::before {
        content: "";
        position: absolute;
        background-color: #333;
        height: 12px;
        width: 2px;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }
    &::before {
        transform: rotate(90deg);
        top: calc(50% - 6px);
        left: calc(50% - 1px);
    }
}
</style>